export default function useParallax(
  element: HTMLElement,
  rangeLimits: Array<number>
): void {
  const reposition = () => {
    requestAnimationFrame(() => {
      const screenHeight = window.innerHeight || screen.height;
      const topOfScreen = window.scrollY || document.documentElement.scrollTop;
      const bottomOfScreen = topOfScreen + screenHeight;
      const elBounding = element.getBoundingClientRect();
      const elementTop = elBounding.top + topOfScreen;
      const elementBottom = elementTop + elBounding.height;

      const isInView =
        elementBottom > topOfScreen && elementTop < bottomOfScreen;

      // Only apply the effect if the element is in the viewport
      if (isInView) {
        const elementPosition = elementTop + elBounding.height / 2;

        // Calculate the range of the top and bottom of the screen
        const screenRangeLow = topOfScreen - elBounding.height;
        const screenRangeHigh = bottomOfScreen + elBounding.height;
        const screenRange = screenRangeHigh - screenRangeLow;

        // Calculate the range for the y percentages
        const [percentageLow, percentageHigh] = rangeLimits;
        const percentageRange = percentageHigh - percentageLow;

        // Convert the value of the element screen positions relative to the screen range
        // to an equal value inside the percentage range
        // https://stackoverflow.com/questions/929103/convert-a-number-range-to-another-range-maintaining-ratio
        const yPercentage =
          ((elementPosition - screenRangeLow) * percentageRange) / screenRange +
          percentageLow;

        element.style.backgroundPosition =
          " center " + (100 - yPercentage) + "%";
      }
    });
  };

  window.addEventListener("scroll", reposition);
  reposition();
}
