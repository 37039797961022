
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useStore } from "vuex";
import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";
import { BSection, BPagination, BPhotosModal } from "@/components";
import { SectionIds } from "@/enums";
import { useGallery } from "@/hooks";
import { maxImageHeights, galleryGaps, maxRowsPerPage } from "@/constants";
import { BImage } from "@/models/Image";

// TODO:
// - Add onclick action
export default defineComponent({
  components: { BSection, BPagination, BPhotosModal },
  setup() {
    const store = useStore();
    const { title, coverPhoto, photos } = store.state.content.gallerySection;
    const container = ref<HTMLElement>(null as any);
    const clickedPhotoId = ref("");
    const isModalShowing = ref(false);

    const pages = ref<BImage[][]>([]);
    const pageNumber = ref(0);
    const numberOfPages = computed(() => {
      return pages.value.length;
    });

    const currentPage = computed(() => {
      return pages.value[pageNumber.value];
    });

    const showModal = (id: string) => {
      isModalShowing.value = true;
      clickedPhotoId.value = id;
    };

    onMounted(() => {
      const photosByPages = useGallery(
        container,
        photos,
        maxRowsPerPage,
        maxImageHeights,
        galleryGaps
      );

      watchEffect(() => {
        pages.value = photosByPages.value;
        // Adapt the current page number if the number of pages has changed
        if (pageNumber.value >= pages.value.length) {
          pageNumber.value = pages.value.length - 1;
        }
      });
    });

    const nextPage = (scrollToTop?: boolean) => {
      let timeout = 0;
      if (scrollToTop) {
        const content = document.getElementById(SectionIds.Gallery);
        content?.scrollIntoView();
        timeout = 200;
      }

      setTimeout(() => {
        ++pageNumber.value;
      }, timeout);
    };

    const previousPage = (scrollToTop?: boolean) => {
      let timeout = 0;
      if (scrollToTop) {
        const content = document.getElementById(SectionIds.Gallery);
        content?.scrollIntoView();
        timeout = 200;
      }

      setTimeout(() => {
        --pageNumber.value;
      }, timeout);
    };

    return {
      SectionIds,
      title,
      coverPhoto,
      currentPage,
      container,
      numberOfPages,
      pageNumber,
      nextPage,
      previousPage,
      showModal,
      clickedPhotoId,
      isModalShowing,
    };
  },
});
