<template>
  <svg
    id="HEART"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 142 121"
    xml:space="preserve"
    :style="{ fill: color }"
  >
    <g transform="matrix(1 0 0 1 71.36 60.65)">
      <path
        transform=" translate(-382.67, -70.85)"
        d="M 382.68 131 a 20.88 20.88 0 0 1 -15.1 -6.44 L 323.08 78.7 a 40.72 40.72 0 0 1 0 -56.25 a 38.15 38.15 0 0 1 55.09 0 l 4.5 4.65 l 4.5 -4.65 a 38.15 38.15 0 0 1 55.09 0 a 40.72 40.72 0 0 1 0 56.25 L 397.78 124.6 A 20.88 20.88 0 0 1 382.68 131 Z m -32 -107.27 a 25.13 25.13 0 0 0 -18.15 7.78 a 27.55 27.55 0 0 0 0 38.05 L 377 115.5 a 7.86 7.86 0 0 0 11.42 0 L 432.88 69.6 a 27.55 27.55 0 0 0 0 -38.05 a 25.08 25.08 0 0 0 -36.31 0 L 382.68 45.88 L 368.78 31.55 A 25.13 25.13 0 0 0 350.63 23.77 Z"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: "#606060",
    },
  },
});
</script>
