<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 142 142"
    style="enable-background: new 0 0 425.2 100.6"
    xml:space="preserve"
  >
    <g transform="matrix(1 0 0 1 71.36 71.36)">
      <g style="">
        <g transform="matrix(1 0 0 1 36.75 -36.69)">
          <rect
            x="-8.74"
            y="-8.74"
            rx="8.74"
            ry="8.74"
            width="17.48"
            height="17.48"
            :style="{ fill: color }"
          />
        </g>
        <g transform="matrix(1 0 0 1 0 0)">
          <path
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-dashoffset: 0;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            transform=" translate(-226.72, -70.86)"
            d="M 255.49 141.73 H 198 a 42.19 42.19 0 0 1 -42.14 -42.14 V 42.14 A 42.19 42.19 0 0 1 198 0 h 57.44 a 42.19 42.19 0 0 1 42.14 42.14 V 99.59 A 42.19 42.19 0 0 1 255.49 141.73 Z M 198 12.88 a 29.29 29.29 0 0 0 -29.26 29.26 V 99.59 A 29.29 29.29 0 0 0 198 128.85 h 57.44 a 29.29 29.29 0 0 0 29.26 -29.26 V 42.14 a 29.29 29.29 0 0 0 -29.26 -29.26 Z"
            stroke-linecap="round"
            :style="{ fill: color }"
          />
        </g>
        <g transform="matrix(1 0 0 1 0.05 0.01)">
          <path
            style="
              stroke: none;
              stroke-width: 1;
              stroke-dasharray: none;
              stroke-linecap: butt;
              stroke-dashoffset: 0;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              fill: rgb(0, 0, 0);
              fill-rule: nonzero;
              opacity: 1;
            "
            transform=" translate(-226.77, -70.87)"
            d="M 226.77 106.48 a 35.61 35.61 0 1 1 35.61 -35.61 A 35.65 35.65 0 0 1 226.77 106.48 Z m 0 -58.34 A 22.73 22.73 0 1 0 249.5 70.87 A 22.75 22.75 0 0 0 226.77 48.14 Z"
            stroke-linecap="round"
            :style="{ fill: color }"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: "#606060",
    },
  },
});
</script>
