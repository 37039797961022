import { SectionIds } from "@/enums";
import { MainPage } from "@/models";
import { createStore } from "vuex";
import { fetchContent } from "@/api";

export default createStore({
  state: {
    content: {} as MainPage,
    locale: "de-DE",
    loadingData: true,
    loadingImages: true,
    visibleSections: {
      [SectionIds.About]: false,
      [SectionIds.Gallery]: false,
      [SectionIds.MakingOf]: false,
      [SectionIds.Yarns]: false,
      [SectionIds.Contact]: false,
    },
  },
  mutations: {
    toggleSection(state, id: SectionIds) {
      state.visibleSections[id] = !state.visibleSections[id];
    },
    expandSection(state, id: SectionIds) {
      state.visibleSections[id] = true;
    },
    collapseSection(state, id: SectionIds) {
      state.visibleSections[id] = false;
    },
    collapseAll(state) {
      for (const id in state.visibleSections) {
        state.visibleSections[id as SectionIds] = false;
      }
    },
  },
  actions: {
    async fetchData({ state, dispatch }) {
      state.loadingData = true;
      try {
        state.content = await fetchContent(state.locale);
        state.loadingData = false;
        dispatch("loadSectionImages");
      } catch (err) {
        console.log(err); // TODO: Handle error
      }
    },
    async loadSectionImages({ state }) {
      // Preload all images
      await state.content.loadImages();
      state.loadingImages = false;
    },
  },
});
