<template>
  <BSection :sectionId="SectionIds.About" :name="title" :img="coverPhoto">
    <div v-if="showMobileLayout">
      <div class="slide-container">
        <img
          v-for="(photo, index) in photos"
          :key="index"
          :src="photo.url"
          class="img-mobile"
          :class="{
            'opacity-100': imageNumber === index,
            'opacity-0': imageNumber !== index,
          }"
        />
      </div>
      <div class="mt-10">
        <p class="text" v-html="text"></p>
      </div>
    </div>
    <div v-else class="about-container gap-10">
      <div>
        <p class="text" v-html="text"></p>
      </div>
      <div class="photo-grid grid grid-cols-3 grid-rows-2 gap-6">
        <div class="col-span-1 row-span-1" :style="getStyle(0)"></div>
        <div class="col-span-1 row-span-1 bg-center" :style="getStyle(1)"></div>
        <div class="col-span-1 row-span-2" :style="getStyle(2)"></div>
        <div class="col-span-2 row-span-1 bg-center" :style="getStyle(3)"></div>
      </div>
    </div>
  </BSection>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, computed, ref } from "vue";
import { BSection } from "@/components";
import { SectionIds } from "@/enums";
import { useCurrentBreakpoint } from "@/hooks";

export default defineComponent({
  components: { BSection },
  setup() {
    const store = useStore();
    const currentBreakPoint = useCurrentBreakpoint();
    const { title, coverPhoto, text, photos } =
      store.state.content.aboutSection;
    const getStyle = (index) => {
      return {
        "background-image": `url("${photos[index].url}")`,
      };
    };

    // Mobile related properties
    const imageNumber = ref(0);
    const showMobileLayout = computed(() => {
      return (
        currentBreakPoint.value === "sm" || currentBreakPoint.value === "md"
      );
    });

    setInterval(() => {
      imageNumber.value =
        imageNumber.value < photos.length - 1 ? imageNumber.value + 1 : 0;
    }, 5000);

    return {
      SectionIds,
      title,
      coverPhoto,
      text,
      photos,
      getStyle,
      showMobileLayout,
      imageNumber,
    };
  },
});
</script>

<style scoped lang="postcss">
.about-container {
  display: grid;
  grid-template-columns: 1.5fr 2.5fr;
}

.photo-grid > div {
  background-size: cover;
}

.photo-grid > div::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: 32%;
}

.slide-container {
  @apply w-full relative;
  height: 190px;
}

.img-mobile {
  @apply transition duration-1000 h-full w-full top-0;
  position: absolute;
  object-fit: cover;
}

.text {
  @apply text-justify text-gray-elke text-xs;
  max-height: 220px;
  overflow: scroll;
  padding-right: 4px;
}

@media (min-width: theme("screens.md")) {
  .slide-container {
    height: 350px;
  }
}

@media (min-width: theme("screens.lg")) {
  .text {
    @apply text-base;
    max-height: 550px;
    overflow: scroll;
    padding-right: 24px;
  }
}
</style>
