<template>
  <div
    class="burger-menu select-none"
    :class="{
      open: isOpened,
    }"
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="postcss">
.burger-menu {
  width: 30px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.burger-menu span {
  @apply bg-gray-elke;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger-menu.open span {
  @apply bg-lime-elke;
}

.burger-menu span:nth-child(1) {
  top: 0px;
}

.burger-menu span:nth-child(2),
.burger-menu span:nth-child(3) {
  top: 10px;
}

.burger-menu span:nth-child(4) {
  top: 20px;
}

.burger-menu.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

.burger-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger-menu.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}
</style>
