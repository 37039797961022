<template>
  <div class="footer">
    <div class="footer-section">
      <HomeIcon class="icon" />
      <div>{{ schedule }}</div>
    </div>
    <div class="footer-section">
      <InstagramIcon class="icon" />
      <a
        :href="instagramLink"
        target="_blank"
        class="cursor-pointer hover:underline"
        >{{ instagram }}</a
      >
    </div>
    <div class="footer-section">
      <HeartIcon class="icon" />
      <div>{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { HeartIcon, HomeIcon, InstagramIcon } from "@/components";

export default defineComponent({
  components: {
    HeartIcon,
    HomeIcon,
    InstagramIcon,
  },
  setup() {
    const store = useStore();
    const { schedule, instagram, message, instagramLink } =
      store.state.content.footer;

    return {
      schedule,
      instagram,
      message,
      instagramLink,
    };
  },
});
</script>

<style scoped lang="postcss">
.footer {
  @apply flex justify-center gap-2 px-4 md:px-8 text-gray-elke;
  font-size: 8px;
}

.footer-section {
  @apply flex gap-3 items-center;
}

.icon {
  @apply h-8 w-8;
}

@media (min-width: theme("screens.md")) {
  .footer {
    @apply gap-12 text-base;
  }
}
</style>
