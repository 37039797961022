<template>
  <div
    class="
      fixed
      navbar
      inset-0
      px-4
      md:px-6
      flex
      justify-between
      items-center
      z-50
    "
  >
    <router-link to="/">
      <BMainLogo
        class="h-8 md:h-12 2xl:h-16 cursor-pointer"
        @click="onLogoClick"
      />
    </router-link>
    <!-- Mobile menu -->
    <div v-if="isMobile">
      <BurgerMenu
        :isOpened="isMenuOpened"
        @click="toggleMenu"
        role="button"
        aria-label="Mobile navigation menü"
        :aria-pressed="isMenuOpened"
      />
      <div
        class="
          mobile-menu
          absolute
          right-0
          bg-white
          h-screen
          pl-4
          pt-4
          transition-all
          duration-500
        "
        :class="{
          'w-0 opacity-0 ': !isMenuOpened,
          'w-40 opacity-80': isMenuOpened,
        }"
      >
        <ol class="flex flex-col gap-5">
          <li>
            <BNavItem
              :text="aboutSection.title"
              :id="SectionIds.About"
              @click="toggleMenu"
            />
          </li>
          <li>
            <BNavItem
              :text="makingOfSection.title"
              :id="SectionIds.MakingOf"
              @click="toggleMenu"
            />
          </li>
          <li>
            <BNavItem
              :text="yarnsSection.title"
              :id="SectionIds.Yarns"
              @click="toggleMenu"
            />
          </li>
          <li>
            <BNavItem
              :text="gallerySection.title"
              :id="SectionIds.Gallery"
              @click="toggleMenu"
            />
          </li>
          <li>
            <BNavItem
              :text="contactSection.title"
              :id="SectionIds.Contact"
              @click="toggleMenu"
            />
          </li>
        </ol>
      </div>
    </div>
    <!-- Desktop menu -->
    <nav v-else>
      <ol class="flex gap-5">
        <li>
          <BNavItem :text="aboutSection.title" :id="SectionIds.About" />
        </li>
        |
        <li>
          <BNavItem :text="makingOfSection.title" :id="SectionIds.MakingOf" />
        </li>
        |
        <li>
          <BNavItem :text="yarnsSection.title" :id="SectionIds.Yarns" />
        </li>
        |
        <li>
          <BNavItem :text="gallerySection.title" :id="SectionIds.Gallery" />
        </li>
        |
        <li>
          <BNavItem :text="contactSection.title" :id="SectionIds.Contact" />
        </li>
      </ol>
    </nav>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { BNavItem, BMainLogo, BurgerMenu } from "@/components";
import { SectionIds } from "@/enums";
import { useCurrentBreakpoint } from "@/hooks";
export default defineComponent({
  components: {
    BNavItem,
    BMainLogo,
    BurgerMenu,
  },
  setup() {
    const store = useStore();
    const currentBreakpoint = useCurrentBreakpoint();

    const isMenuOpened = ref(false);
    const isMobile = computed(() => {
      return currentBreakpoint.value === "sm";
    });

    const onLogoClick = () => {
      window.scrollTo({ top: 0 });
      store.commit("collapseAll");
    };

    const toggleMenu = () => {
      isMenuOpened.value = !isMenuOpened.value;
    };

    return {
      onLogoClick,
      toggleMenu,
      SectionIds,
      isMobile,
      isMenuOpened,
      ...store.state.content,
    };
  },
});
</script>

<style lang="postcss" scoped>
.navbar {
  background: rgba(255, 255, 255, 0.75);
  height: var(--navbar-height);
  backdrop-filter: blur(4px);
}
li {
  position: relative;
}

.mobile-menu {
  top: 4rem;
}
</style>
