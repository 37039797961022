module.exports = {
  purge: { content: ["./public/**/*.html", "./src/**/*.vue"] },
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      cursor: {
        pointer: "url(/cursor-pointer.ico) 15 15, pointer",
      },
      fontSize: {
        "10xl": ["9rem", "9rem"],
      },
      screens: {
        sm: "10px",
        "2xl": "2000px",
      },
      boxShadow: {
        full: "-1px 2px 39px -19px rgba(0,0,0,0.75)",
      },
      opacity: {
        15: "0.15",
      },
      colors: {
        gray: {
          elke: "#676767",
        },
        yellow: {
          elke: "#e5c170",
          elkeLight: "#e8c77e",
          elkeDark: "#ceae65",
        },
        lime: {
          elke: "#cbcb54",
          button: "#dcdf9d",
          buttonLight: "#d5d576",
          buttonDark: "#8e8e3a",
        },
      },
    },
  },
  variants: {
    extend: {
      opacity: ["group-hover"],
      backgroundColor: ["active"],
    },
  },
  plugins: [],
};
