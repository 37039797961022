<template>
  <BSection :sectionId="SectionIds.MakingOf" :name="title" :img="coverPhoto">
    <BSlideshow :slides="slides" />
  </BSection>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent } from "vue";
import { BSection, BSlideshow } from "@/components";
import { SectionIds } from "@/enums";

export default defineComponent({
  components: { BSection, BSlideshow },
  setup() {
    const store = useStore();
    const { title, coverPhoto, slides } = store.state.content.makingOfSection;

    return {
      SectionIds,
      title,
      coverPhoto,
      slides,
    };
  },
});
</script>

<style>
.slide {
  height: 525px;
}

.photo {
  background-size: cover;
  background-position: center;
}
</style>
