<template>
  <!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 192.8 62.4"
    style="enable-background: new 0 0 192.8 62.4"
    xml:space="preserve"
  >
    <g id="LOGO">
      <path
        class="st0"
        d="M192.4,51.5c-10.1,3.2-20.9,3.7-27.6,1.2c-3.8-2-4.7-5.4-5.8-9.4c-1.2-4.5-2.5-9.6-8.5-13.3
          c-10.5-6.4-30.9,0.1-38.6,2.5l-1.3,0.4c-1.4,0.4-3.2,1.1-5.6,1.9c-7.3,2.6-19.6,7-31.9,9c-15.9,2.6-36.4,0.1-41.6-1.5
          c-0.6-0.2-1.2-0.4-1.8-0.6l0-0.6c0.1-10.9,0.1-12.3-3.5-15.8L26,25.1c-5.2-3.4-9.6-1.7-11.8,0.7c-2.3,2.6-3.1,7.4,0.4,11.5
          c2.9,3.4,7.6,5.5,11.5,6.9c0,1.1,0,2.3,0,3.5c0,7.5-5.7,10.9-11,10.9c-0.1,0-0.1,0-0.2,0c-3.1,0-6-1.1-8-3.1c-2-2-3.1-4.8-3.1-8
          l0-0.1c0,0-0.2-2.9-0.3-6.2C3.5,35.5,3.7,2.1,3.7,1.8c0-0.5-0.2-0.9-0.5-1.3C2.9,0.2,2.4,0,2,0C1.5,0,1.1,0.2,0.7,0.5
          C0.4,0.8,0.2,1.3,0.2,1.8c0,0.3-0.2,33.7-0.2,39.5c0,3.2,0.3,6.1,0.3,6.4c0.1,9.6,7.6,14.7,14.8,14.7l0.1,0
          c7.2,0,14.5-5.1,14.5-14.6c0-0.8,0-1.6,0-2.3c0.3,0.1,0.5,0.2,0.8,0.2c6.2,2,27.1,4.3,43.3,1.7c12.6-2.1,25-6.5,32.5-9.1
          c2.3-0.8,4.1-1.5,5.4-1.9L113,36c6.6-2.1,26.5-8.4,35.6-2.8c4.7,2.9,5.8,6.9,6.9,11.1c1.2,4.4,2.4,8.9,7.7,11.7l0.2,0.1
          c7.4,2.8,18.3,2.5,29.2-0.8l0.2-0.1v-3.7L192.4,51.5z M26.2,40.5c-4.3-1.6-7.1-3.4-8.8-5.4c-2.2-2.6-1.8-5.3-0.5-6.8
          c0.8-0.9,2-1.4,3.3-1.4c1.2,0,2.5,0.4,3.7,1.2C26.1,30.3,26.2,30.6,26.2,40.5z M15.2,62.1L15.2,62.1L15.2,62.1L15.2,62.1z"
      />
    </g>
  </svg>
</template>

<style lang="postcss" scoped>
.st0 {
  @apply text-white fill-current opacity-80;
}
</style>
