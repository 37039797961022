<template>
  <BSection :sectionId="SectionIds.Contact" :name="title" :img="coverPhoto">
    <div class="contact-grid">
      <!-- Photo -->
      <img :src="contentPhoto.url" class="photo h-full w-full" />

      <!-- Form -->
      <div>
        <form
          class="flex flex-col gap-6"
          :action="'https://formsubmit.co/' + businessEmail"
          method="POST"
          aria-label="Nachricht senden"
        >
          <input name="name" type="text" :placeholder="formName" required />
          <input name="address" type="text" :placeholder="formAddress" />
          <input name="phone" type="text" :placeholder="formPhone" />
          <input name="email" type="email" :placeholder="formEmail" required />
          <textarea
            name="message"
            rows="5"
            :placeholder="formMessage"
            required
          />

          <button
            type="submit"
            class="
              h-12
              w-full
              bg-lime-button
              transition
              rounded-sm
              font-medium
              tracking-wider
              text-white
              hover:bg-lime-buttonLight
              active:bg-lime-buttonDark
            "
          >
            {{ sendButton }}
          </button>
        </form>
      </div>

      <!-- Info -->
      <div class="flex flex-col h-auto items-start">
        <a
          :href="googleMapsLink"
          target="_blank"
          class="h-72 w-full relative cursor-pointer"
        >
          <div
            :style="mapStyle"
            class="map h-full w-full transition duration-300"
          ></div>
        </a>
        <BMainLogo class="h-12 mt-8 md:mt-12" :color="elkeYellow" />
        <p>{{ infoName }}</p>
        <p>{{ infoAddress }}</p>
        <p>{{ infoPhone }}</p>
        <p>{{ infoEmail }}</p>
      </div>
    </div>
  </BSection>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, ref, inject } from "vue";
import { BSection, BMainLogo } from "@/components";
import { SectionIds } from "@/enums";

export default defineComponent({
  components: { BSection, BMainLogo },
  setup() {
    const store = useStore();
    const tailwindConfig = inject("tailwindConfig");
    const elkeYellow = tailwindConfig.theme.colors.lime.elke;
    const { contentPhoto, mapPhoto } = store.state.content.contactSection;
    const photoStyle = ref({
      "background-image": `url("${contentPhoto.url}")`,
    });
    const mapStyle = ref({
      "background-image": `url("${mapPhoto.url}")`,
      "background-size": "cover",
      "background-position": "center",
    });
    return {
      SectionIds,
      photoStyle,
      mapStyle,
      elkeYellow,
      ...store.state.content.contactSection, // TODO: Might not be reactive. Check when implementing localization
    };
  },
});
</script>

<style lang="postcss" scoped>
.contact-grid {
  @apply grid grid-cols-1 gap-12;
}

.photo {
  object-fit: cover;
  object-position: left top;
}

textarea {
  @apply w-full bg-gray-200 rounded-sm px-4 pt-4  transition duration-300;
  resize: none;
  outline: none;
}

input {
  @apply h-12 w-full bg-gray-200 rounded-sm px-4 outline-none transition duration-300;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus,
textarea:focus {
  @apply bg-gray-300;
}

.map:hover {
  filter: brightness(0.9);
}

p {
  @apply text-gray-elke text-base mt-1;
}

@media (min-width: theme("screens.md")) {
  p {
    @apply text-xl;
  }
}

@media (min-width: theme("screens.lg")) {
  .contact-grid {
    @apply grid-cols-3;
    grid-template-columns: 1.1fr 1fr 0.9fr;
  }
}
</style>
