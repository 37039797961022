export const query = (locale: string): string => `{
  landingPhoto(id: "4iswTqAst2A7HYv2Kegpnd") {
    photo {
      url,
      width,
      height
    },
    fallbackPhoto {
      url,
      width,
      height
    }
  },
  aboutSection(id: "6iS9V15kthWQN9wiRvIeR4", locale: "${locale}") {
    title,
    coverPhoto {
      url,
      width,
      height
    },
    text {
      json
    },
    photosCollection {
      items {
        url,
        width,
        height
      }
    }
  },
  gallerySection(id: "3riuOk6Benvy6bXbtRC4en", locale: "${locale}") {
    title,
    coverPhoto {
      url,
      width,
      height
    },
     photosCollection {
      items {
        url,
        width,
        height
      }
    }
  },
  makingOfSection(id: "2x9mfQRyW8bAPEZhA8UOJH", locale: "${locale}") {
    title,
    coverPhoto {
      url,
      width,
      height
    },
    slidesCollection {
      items {
        ... on SlideItem {
          title,
          description {
            json
          },
          photo {
            url,
            width,
            height
          }
        }
      }
    }
  },
   yarnsSection(id: "7bEe7y9mEi9u0gCWlBlBsM", locale: "${locale}") {
    title,
    coverPhoto {
      url,
      width,
      height
    },
    text1,
    text2,
    text3,
    photosCollection {
      items {
        url,
        width,
        height
      }
    }
  },
  contactSection(id: "5lbGKM6N4CEjgvkr7O4zeH", locale: "${locale}") {
    title,
    businessEmail,
    coverPhoto {
      url,
      width,
      height
    },
    contentPhoto {
      url,
      width,
      height
    },
    mapPhoto {
      url,
      width,
      height
    },
    formName,
    formAddress,
    formPhone,
    formEmail,
    formMessage,
    sendButton,
    infoName,
    infoAddress,
    infoPhone,
    infoEmail,
    googleMapsLink
  },
  footer(id: "16pwsP8xdvztRpZONdLtFB") {
    schedule,
    instagram,
    message,
    instagramLink
  },
}`;
