/* eslint-disable @typescript-eslint/no-explicit-any */
import { BImage } from "@/models/Image";
import { Section } from "@/models/Section";
import { richtextToPlainText } from "@/utils";

class AboutSection extends Section {
  public readonly photos: Array<BImage>;
  public readonly text: string;
  constructor(json: any) {
    super(json);
    this.photos = json.photosCollection.items.map(
      (item: any) => new BImage(item, 900)
    );
    this.text = richtextToPlainText(json.text.json).replaceAll(
      "\n\n",
      "<br/> <br/>"
    );
  }
}
export default AboutSection;
