<template>
  <div class="modal-mask" :style="{ height: maskHeight }">
    <div class="modal-wrapper">
      <div class="modal-container max-h-screen">
        <div class="modal-header">
          <slot name="header"> </slot>
        </div>
        <div class="modal-body">
          <slot name="body"> </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onUnmounted, ref } from "vue";

export default defineComponent({
  setup() {
    const maskHeight = ref(window.innerHeight * 0.01);
    const updateHeight = () => {
      maskHeight.value = window.innerHeight * 0.01;
    };

    window.addEventListener("resize", updateHeight);

    onUnmounted(() => {
      window.removeEventListener("resize", updateHeight);
    });

    return {
      maskHeight,
    };
  },
});
</script>

<style lang="postcss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(90, 90, 90, 0.8);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  margin: 0px auto;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}
</style>
