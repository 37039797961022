import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ed9c24a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide relative m-auto w-full" }
const _hoisted_2 = {
  key: 0,
  class: "content absolute p-8 pt-12 text-gray-700"
}
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 3,
  class: "dots"
}
const _hoisted_6 = {
  key: 0,
  class: "h-44 mt-8 text-gray-700 overflow-scroll pr-1"
}
const _hoisted_7 = { class: "relative" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "mt-1 mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownArrow = _resolveComponent("DownArrow")!
  const _component_BPagination = _resolveComponent("BPagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["photo absolute w-full h-full transition duration-1000", {
        'opacity-100': index === _ctx.slideNumber,
        'opacity-0': index !== _ctx.slideNumber,
      }]),
          key: index,
          style: _normalizeStyle({
        'background-image': 'url(' + slide.photo.url + ')',
      })
        }, null, 6))
      }), 128)),
      (!_ctx.showMobileLayout)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["absolute transition duration-1000", {
            'opacity-100': index === _ctx.slideNumber,
            'opacity-0': index !== _ctx.slideNumber,
          }]),
                  key: index
                }, [
                  _createElementVNode("p", {
                    class: "font-medium text-justify",
                    innerHTML: slide.description
                  }, null, 8, _hoisted_4)
                ], 2))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.showMobileLayout)
        ? (_openBlock(), _createBlock(_component_DownArrow, {
            key: 1,
            class: "arrow left cursor-pointer",
            onClick: _ctx.previousSlide
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.showMobileLayout)
        ? (_openBlock(), _createBlock(_component_DownArrow, {
            key: 2,
            class: "arrow right cursor-pointer",
            onClick: _ctx.nextSlide
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.showMobileLayout)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfSlides, (index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["h-3 w-3 rounded-full transition-all duration-500", {
          'bg-transparent border-4 border-white h-5 w-5 opacity-100':
            index === _ctx.slideNumber + 1,
          'bg-white opacity-80': index !== _ctx.slideNumber + 1,
        }])
              }, null, 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showMobileLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["absolute transition duration-1000", {
          'opacity-100': index === _ctx.slideNumber,
          'opacity-0 hidden': index !== _ctx.slideNumber,
        }]),
                key: index
              }, [
                _createElementVNode("p", {
                  class: "text-justify text-xs",
                  innerHTML: slide.description
                }, null, 8, _hoisted_8)
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showMobileLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_BPagination, {
            numberOfPages: _ctx.numberOfSlides,
            currentPage: _ctx.slideNumber + 1,
            onPreviousPage: _ctx.previousSlide,
            onNextPage: _ctx.nextSlide,
            loop: true
          }, null, 8, ["numberOfPages", "currentPage", "onPreviousPage", "onNextPage"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}