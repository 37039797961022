/* eslint-disable @typescript-eslint/no-explicit-any */
import { BImage } from "@/models/Image";
import { Section } from "@/models/Section";

class YarnsSection extends Section {
  public readonly photos: Array<BImage>;
  public readonly text1: string;
  public readonly text2: string;
  public readonly text3: string;
  constructor(json: any) {
    super(json);
    this.photos = json.photosCollection.items.map(
      (item: any) => new BImage(item, 800)
    );
    this.text1 = json.text1;
    this.text2 = json.text2;
    this.text3 = json.text3;
  }
}

export default YarnsSection;
