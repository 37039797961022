import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a7205fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "logo",
  class: "line-logo-container absolute"
}
const _hoisted_2 = { class: "flex flex-col gap-12 md:gap-20 2xl:gap-24 mt-12 md:mt-20 2xl:mt-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_line_logo = _resolveComponent("line-logo")!
  const _component_About = _resolveComponent("About")!
  const _component_MakingOf = _resolveComponent("MakingOf")!
  const _component_Yarns = _resolveComponent("Yarns")!
  const _component_Gallery = _resolveComponent("Gallery")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_BFooter = _resolveComponent("BFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.style),
      class: "landing-photo relative"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_line_logo, { class: "line-logo" })
      ], 512)
    ], 4),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_About),
      _createVNode(_component_MakingOf),
      _createVNode(_component_Yarns),
      _createVNode(_component_Gallery),
      _createVNode(_component_Contact),
      _createVNode(_component_BFooter, { class: "mt-4 md:mt-12" })
    ])
  ], 64))
}