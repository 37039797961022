/* eslint-disable @typescript-eslint/no-explicit-any */
import { BImage } from "@/models/Image";
import { richtextToPlainText } from "@/utils";

export class SlideShowItem {
  public readonly title: string;
  public readonly description: string;
  public readonly photo: BImage;

  constructor(json: any) {
    this.title = json.title;
    this.photo = this.formatPhoto(json.photo);
    this.description = richtextToPlainText(json.description.json).replaceAll(
      "\n",
      ""
    );
  }

  formatPhoto(json: any): BImage {
    const screenWidth = window.innerWidth || screen.width;
    const screenHeight = window.innerHeight || screen.height;
    const imageWidth =
      screenWidth > screenHeight ? screenWidth : screenWidth * 1.8;
    return new BImage(json, undefined, imageWidth);
  }
}

export default SlideShowItem;
