import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/main.css";
import smoothscroll from "smoothscroll-polyfill";

// Initialize scrolling polyfill
smoothscroll.polyfill();

// Initialize app
createApp(App).use(store).use(router).mount("#app");
