/* eslint-disable @typescript-eslint/no-explicit-any */
import { BImage } from "@/models/Image";
import { Section } from "@/models/Section";
class GallerySection extends Section {
  public readonly photos: Array<BImage>;
  constructor(json: any) {
    super(json);
    this.photos = json.photosCollection.items.map(
      (item: any) => new BImage(item, 1300)
    );
  }
}

export default GallerySection;
