<template>
  <a
    ref="navitem"
    :class="{ isActive }"
    :href="href"
    class="
      text-gray-elke text-md
      2xl:text-lg
      transition
      duration-300
      font-semibold
      active:text-red
      cursor-pointer
    "
    @click.prevent="onClick"
  >
    {{ text }}
  </a>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { scrollToSection } from "@/utils";

export default defineComponent({
  name: "BNavItem",
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const href = ref("/#" + props.id);
    const navitem = ref(null);
    const isActive = ref(false);

    const onClick = () => {
      isActive.value = true;
      store.commit("expandSection", props.id);
      scrollToSection(props.id);
    };

    // Check whether the anchor is active (the corresponding section is displayed on the page)
    let timer = null;
    window.addEventListener("scroll", () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      // Wait for the scroll event to finish
      timer = setTimeout(() => {
        const section = document.getElementById(props.id);
        const banner = document.getElementById("banner-" + props.id);

        const navBound = navitem.value.getBoundingClientRect();
        const contentBound = section.getBoundingClientRect();
        const bannerBound = banner.getBoundingClientRect();

        const overlap =
          navBound.bottom > bannerBound.top &&
          navBound.top < contentBound.bottom;

        isActive.value = overlap && store.state.visibleSections[props.id];
      }, 20);
    });
    return {
      href,
      navitem,
      isActive,
      onClick,
    };
  },
});
</script>

<style lang="postcss" scoped>
a:active,
a.isActive {
  @apply text-lime-elke !important;
}

a:hover {
  @apply text-gray-700;
}

/* a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: theme("colors.gray.elke");
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

/* a.isActive:after,
a:hover:after {
  width: 100%;
  left: 0;
}

a.isActive:after {
  @apply bg-lime-elke;
} */
</style>
