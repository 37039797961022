/* eslint-disable @typescript-eslint/no-explicit-any */
import { BImage } from "@/models/Image";
import { Section } from "@/models/Section";

class ContactSection extends Section {
  public contentPhoto: BImage;
  public mapPhoto: BImage;
  public businessEmail: string;
  public formName: string;
  public formAddress: string;
  public formPhone: string;
  public formEmail: string;
  public formMessage: string;
  public sendButton: string;
  public infoName: string;
  public infoAddress: string;
  public infoPhone: string;
  public infoEmail: string;
  public googleMapsLink: string;
  constructor(json: any) {
    super(json);
    this.contentPhoto = new BImage(json.contentPhoto, 800);
    this.mapPhoto = new BImage(json.mapPhoto, 800);
    this.businessEmail = json.businessEmail;
    this.formName = json.formName;
    this.formAddress = json.formAddress;
    this.formPhone = json.formPhone;
    this.formEmail = json.formEmail;
    this.formMessage = json.formMessage;
    this.sendButton = json.sendButton;
    this.infoName = json.infoName;
    this.infoAddress = json.infoAddress;
    this.infoPhone = json.infoPhone;
    this.infoEmail = json.infoEmail;
    this.googleMapsLink = json.googleMapsLink;
  }
}

export default ContactSection;
