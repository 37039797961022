import { Ref } from "vue";
export default function useExpand(element: Ref<HTMLElement>): {
  expand: () => void;
  collapse: () => void;
} {
  const expand = () => expandElement(element.value);
  const collapse = () => collapseElement(element.value);
  return { expand, collapse };
}

const expandElement = (element: HTMLElement) => {
  // Have the element transition to the height of its inner content
  element.style.height = `${element.scrollHeight}px`;

  // Set the height to auto after expanding the element
  element.addEventListener(
    "transitionend",
    () => {
      element.style.height = "auto";
    },
    {
      once: true,
    }
  );
};

const collapseElement = (element: HTMLElement) => {
  // Get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // Temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = "";

  // On the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(() => {
    element.style.height = `${sectionHeight}px`;
    element.style.transition = elementTransition;

    // On the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(() => {
      element.style.height = "0px";
    });
  });
};
