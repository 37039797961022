/* eslint-disable @typescript-eslint/no-non-null-assertion */
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import { TailwindConfig } from "tailwind.config";
import { ref, getCurrentInstance, onUnmounted } from "vue";

const fullConfig = resolveConfig(tailwindConfig as TailwindConfig);

const getBreakpointValue = (value: string): number =>
  +fullConfig.theme.screens![value].slice(
    0,
    fullConfig.theme.screens![value].indexOf("px")
  );

const findBreakpoint = () => {
  let current = "";
  let biggestBreakpointValue = 0;
  for (const breakpoint of Object.keys(fullConfig.theme.screens!)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (
      breakpointValue > biggestBreakpointValue &&
      window.innerWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue;
      current = breakpoint;
    }
  }

  return current;
};

export default function useCurrentBreakpoint() {
  const currentBreakpoint = ref(findBreakpoint());
  const updateBreakpoint = () => {
    currentBreakpoint.value = findBreakpoint();
  };

  window.addEventListener("resize", updateBreakpoint);

  if (getCurrentInstance()) {
    onUnmounted(() => {
      window.removeEventListener("resize", updateBreakpoint);
    });
  }

  return currentBreakpoint;
}
