/* eslint-disable @typescript-eslint/no-explicit-any */
import { BImage } from "@/models/Image";
import imageLoader from "@/utils/imageLoader";

export class Section {
  public readonly id: string;
  public readonly title: string;
  public readonly coverPhoto: BImage;

  constructor(json: any) {
    this.id = json.id;
    this.title = json.title;
    this.coverPhoto = this.formatCoverPhoto(json.coverPhoto);
  }

  formatCoverPhoto(json: any): BImage {
    const screenWidth = window.innerWidth || screen.width;
    const screenHeight = window.innerHeight || screen.height;
    const imageWidth =
      screenWidth > screenHeight ? screenWidth : screenWidth * 1.8;
    return new BImage(json, undefined, imageWidth);
  }

  async loadCoverPhoto(): Promise<unknown> {
    return imageLoader(this.coverPhoto.url);
  }
}
