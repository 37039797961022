
import { defineComponent } from "vue";
import { DownArrow } from "@/components";
export default defineComponent({
  components: {
    DownArrow,
  },
  emits: ["nextPage", "previousPage"],
  props: {
    numberOfPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
});
