
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DownArrow } from "@/components";
import { useCurrentBreakpoint, useExpand, useParallax } from "@/hooks";
import { scrollToSection } from "@/utils";
import { useStore } from "vuex";
import { BImage } from "@/models/Image";
import { desktopParallaxRanges } from "@/constants";
export default defineComponent({
  components: {
    DownArrow,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
    img: {
      type: Object as () => BImage,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const title = props.name.toLowerCase();
    const content = ref(null as unknown as HTMLElement);
    const section = ref(null);
    const { expand, collapse } = useExpand(content);
    const isVisible = computed(() => {
      return store.state.visibleSections[props.sectionId];
    });
    const style = ref({
      "background-image": `url("${props.img.url}")`,
      "background-repeat": "no-repeat",
    });
    const currentBreakpoint = useCurrentBreakpoint();

    const isMobile = computed(() => {
      return currentBreakpoint.value === "sm";
    });

    onMounted(() => {
      if (content.value && route.hash === `#${props.sectionId}`) {
        store.commit("expandSection", props.sectionId);
      }

      useParallax(
        section.value as unknown as HTMLElement,
        isMobile.value ? [0, 100] : desktopParallaxRanges[props.sectionId]
      );
    });

    watch(isVisible, (show) => {
      if (show) {
        expand();
        scrollToSection(props.sectionId);
      } else {
        router.push("");
        collapse();
      }
    });

    const toggleSection = () => {
      store.commit("toggleSection", props.sectionId);
    };

    return {
      style,
      title,
      isVisible,
      content,
      section,
      toggleSection,
    };
  },
});
