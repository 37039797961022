/* eslint-disable @typescript-eslint/no-explicit-any */
import { Section } from "@/models/Section";
import { SlideShowItem } from "@/models/SlideShowItem";

class MakingOfSection extends Section {
  public readonly slides: Array<SlideShowItem>;
  constructor(json: any) {
    super(json);
    this.slides = json.slidesCollection.items.map(
      (item: any) => new SlideShowItem(item)
    );
  }
}

export default MakingOfSection;
