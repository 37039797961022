import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-771fd68b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center items-center gap-2 md:gap-4 px-8 md:px-12 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownArrow = _resolveComponent("DownArrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DownArrow, {
      class: _normalizeClass(["arrow left cursor-pointer", { disabled: _ctx.loop ? false : _ctx.currentPage === 1 }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('previousPage')))
    }, null, 8, ["class"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfPages, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["h-2.5 w-2.5 md:h-3 md:w-3 bg-gray-300 rounded-full transition duration-300", { 'bg-gray-500': index === _ctx.currentPage }])
      }, null, 2))
    }), 128)),
    _createVNode(_component_DownArrow, {
      class: _normalizeClass(["arrow right cursor-pointer", { disabled: _ctx.loop ? false : _ctx.currentPage === _ctx.numberOfPages }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('nextPage')))
    }, null, 8, ["class"])
  ]))
}