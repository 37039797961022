/* eslint-disable @typescript-eslint/ban-types */
import { BImage } from "@/models/Image";
import { getCurrentInstance, onUnmounted, Ref, ref } from "vue";
import { debounce } from "@/utils";
import { useCurrentBreakpoint } from "@/hooks";

export default function useGallery(
  container: Ref<HTMLElement>,
  images: Array<BImage>,
  maxRowsPerPage: {
    [key: string]: number;
  },
  maxHeights: {
    [key: string]: number;
  },
  gaps: {
    [key: string]: number;
  }
): Ref<Array<Array<BImage>>> {
  const photosByPages: Ref<Array<Array<BImage>>> = ref([]);
  container.value.style.cssText =
    "display: flex; flex-wrap: wrap; justify-content: center;";

  const generateGallery = () => {
    const currentBreakpoint = useCurrentBreakpoint().value;
    const maxHeight = maxHeights[currentBreakpoint];
    const gap = gaps[currentBreakpoint];
    const maxRows = maxRowsPerPage[currentBreakpoint];
    const containerWidth = container.value.getBoundingClientRect().width;
    const _photosByPages = [];
    let imagesOnRow: Array<BImage> = [];
    let numberOfRowsOnPage = 0;
    let photosOnCurrentPage: Array<BImage> = [];

    for (let index = 0; index < images.length; ++index) {
      // Add image to array
      imagesOnRow.push(images[index]);
      imagesOnRow.forEach((i) => i.setHeight(maxHeight));
      const rowWidth = getRowWidth(imagesOnRow);

      // See if the images on the current row are filling the space when having a max height
      if (rowWidth < containerWidth && index !== images.length - 1) {
        // If they don't fill the space, add another image to the row
        continue;
      } else if (rowWidth > containerWidth) {
        // If they go over the container width, resize them to fit and go to the next row
        resizeToFit(imagesOnRow, rowWidth, containerWidth, gap);
        photosOnCurrentPage.push(...imagesOnRow);
        imagesOnRow = [];
      } else {
        // If they fit perfectly, go to the next row
        photosOnCurrentPage.push(...imagesOnRow);
        imagesOnRow = [];
      }

      ++numberOfRowsOnPage;
      if (numberOfRowsOnPage === maxRows || index === images.length - 1) {
        numberOfRowsOnPage = 0;
        _photosByPages.push(photosOnCurrentPage);
        photosOnCurrentPage = [];
      }
    }
    container.value.style.gap = gap + "px";
    photosByPages.value = _photosByPages;
  };

  if (getCurrentInstance()) {
    generateGallery();
    const fn = debounce(generateGallery, 100);
    window.addEventListener("resize", fn);

    onUnmounted(() => {
      window.removeEventListener("resize", fn);
    });
  }

  return photosByPages;
}

const getRowWidth = (images: Array<BImage>): number => {
  return images.reduce((width, image) => width + image.width, 0);
};

const resizeToFit = (
  images: Array<BImage>,
  rowWidth: number,
  containerWidth: number,
  gap: number
): void => {
  images.forEach((image) => {
    const containerWithGaps = containerWidth - (gap * images.length - 1);
    const newWidth = (image.width * containerWithGaps) / rowWidth;
    image.setWidth(newWidth);
  });
};
