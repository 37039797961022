<template>
  <!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 425.2 100.6"
    style="enable-background: new 0 0 425.2 100.6"
    xml:space="preserve"
    class="logo"
  >
    <g id="FILLS">
      <g>
        <path
          class="st0"
          d="M95.7,32.2c-1.3,0.2-2.4,1.2-2.9,2.4l-0.7,1.6c-0.2,0.5-0.8,0.7-1.3,0.4l-1.6-1c-3.7-2.2-7.8-3.4-12.1-3.4
			C64.3,32.1,54,42.5,54,55.3s10.4,23.1,23.1,23.1c4.2,0,8.4-1.2,12.1-3.4l1.6-1c0.5-0.3,1.1-0.1,1.3,0.4l0.7,1.6
			c0.5,1.2,1.6,2.2,2.9,2.4c2.4,0.4,4.5-1.4,4.5-3.8V36C100.2,33.6,98.1,31.8,95.7,32.2z M77.1,70.7c-8.5,0-15.4-6.9-15.4-15.4
			s6.9-15.4,15.4-15.4c8.5,0,15.4,6.9,15.4,15.4S85.6,70.7,77.1,70.7z"
        />
        <path
          class="st0"
          d="M7.4,76l0.7-1.6C8.4,73.9,9,73.7,9.5,74l1.6,1c3.7,2.2,7.8,3.4,12.1,3.4c12.7,0,23.1-10.4,23.1-23.1
			S35.9,32.1,23.1,32.1c-4,0-8,1.1-11.6,3.1l-2.5,1.4c-0.6,0.4-1.4-0.1-1.4-0.8l0-31.8c0-2-1.4-3.9-3.5-4.1C2-0.2,0,1.6,0,3.9v70.7
			c0,2.3,2.1,4.2,4.5,3.8C5.8,78.1,6.9,77.2,7.4,76z M23.1,39.8c8.5,0,15.4,6.9,15.4,15.4s-6.9,15.4-15.4,15.4
			c-8.5,0-15.4-6.9-15.4-15.4S14.6,39.8,23.1,39.8z"
        />
        <path
          class="st0"
          d="M217.1,32.1c-4,0-8,1.1-11.6,3.1l-2.5,1.4c-0.6,0.4-1.4-0.1-1.4-0.8V4.1c0-2-1.4-3.9-3.5-4.1
			c-2.3-0.2-4.3,1.6-4.3,3.8v70.5c0,2,1.4,3.9,3.5,4.1c2.3,0.2,4.3-1.6,4.3-3.8l0-18.7c0-8.2,6.2-15.4,14.4-15.9
			c9-0.6,16.5,6.5,16.5,15.4l0,19.1c0,2,1.4,3.9,3.5,4.1c2.3,0.2,4.3-1.6,4.3-3.8V55.3C240.2,42.5,229.8,32.1,217.1,32.1z"
        />
        <path
          class="st0"
          d="M425.2,36c0-2.3-2-4.1-4.3-3.8c-2,0.2-3.5,2-3.5,4.1v18.5c0,8.2-6.2,15.4-14.4,15.9
			c-9,0.6-16.5-6.5-16.5-15.4V36.2c0-2-1.4-3.9-3.5-4.1c-2.3-0.2-4.3,1.6-4.3,3.8v19.3c0,12.8,10.4,23.1,23.1,23.1
			c4,0,8-1.1,11.6-3.1l2.5-1.4c0.6-0.4,1.4,0.1,1.4,0.8v2.9c0,8.5-6.9,15.4-15.4,15.4h-6.2c-2,0-3.9,1.4-4.1,3.5
			c-0.2,2.3,1.6,4.3,3.8,4.3h6.4h0c3.7,0,7.4-0.8,10.7-2.5c7.9-4.3,12.5-12.1,12.5-20.6V36z"
        />
        <path
          class="st0"
          d="M178.4,70.7c-8.4-0.1-15.3-7-15.3-15.4V40.8c0-0.5,0.4-0.9,0.9-0.9l14.3,0c2,0,3.9-1.4,4.1-3.5
			c0.2-2.3-1.6-4.3-3.8-4.3h-14.5c-0.5,0-0.9-0.4-0.9-0.9l0-27.1c0-2-1.4-3.9-3.5-4.1c-2.3-0.2-4.3,1.6-4.3,3.8v51.4
			c0,12.8,10.4,23.1,23.1,23.1c2.3,0,4.1-2,3.8-4.3C182.2,72.1,180.4,70.7,178.4,70.7z"
        />
        <path
          class="st0"
          d="M134.9,39.8l6.2,0c2,0,3.9-1.4,4.1-3.5c0.2-2.3-1.6-4.3-3.8-4.3h-6.4c-4.2,0-8.4,1.2-12.1,3.4l-1.6,1
			c-0.5,0.3-1.1,0.1-1.3-0.4l-0.7-1.6c-0.5-1.2-1.6-2.2-2.9-2.4c-2.4-0.4-4.5,1.4-4.5,3.8l0,38.3c0,2,1.4,3.9,3.5,4.1
			c2.3,0.2,4.3-1.6,4.3-3.8V55.3C119.5,46.7,126.4,39.8,134.9,39.8z"
        />
        <path
          class="st0"
          d="M308.3,0.1c-2.1,0-3.9,1.7-3.9,3.9v70.7c0,2.1,1.7,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9V4
			C312.2,1.9,310.4,0.1,308.3,0.1z"
        />
        <path
          class="st0"
          d="M272.3,32.1c-12.7,0-23.1,10.4-23.1,23.1s10.4,23.1,23.1,23.1c12.7,0,23.1-10.4,23.1-23.1
			S285.1,32.1,272.3,32.1z M272.3,70.7c-8.5,0-15.4-6.9-15.4-15.4c0-8.5,6.9-15.4,15.4-15.4c8.5,0,15.4,6.9,15.4,15.4
			C287.7,63.8,280.8,70.7,272.3,70.7z"
        />
        <path
          class="st0"
          d="M367.4,74.5V4.1c0-2-1.4-3.9-3.5-4.1c-2.3-0.2-4.3,1.6-4.3,3.8v32c0,0.7-0.8,1.1-1.4,0.8l-2.5-1.4
			c-3.5-2-7.5-3.1-11.6-3.1c-12.7,0-23.1,10.4-23.1,23.1s10.4,23.1,23.1,23.1c4.2,0,8.4-1.2,12.1-3.4l1.6-1c0.5-0.3,1.1-0.1,1.3,0.4
			L360,76c0.5,1.2,1.6,2.2,2.9,2.4C365.3,78.8,367.4,76.9,367.4,74.5z M344.3,70.7c-8.5,0-15.4-6.9-15.4-15.4s6.9-15.4,15.4-15.4
			c8.5,0,15.4,6.9,15.4,15.4S352.8,70.7,344.3,70.7z"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="postcss" scoped>
.logo {
  @apply transition duration-300 fill-current text-gray-elke;
}

.logo:hover {
  @apply fill-current text-lime-elke;
}
</style>
