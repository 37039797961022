import { SectionIds } from "@/enums";

export const maxImageHeights = {
  sm: 300,
  md: 450,
  lg: 500,
  xl: 600,
  "2xl": 600,
};

export const galleryGaps = {
  sm: 8,
  md: 10,
  lg: 14,
  xl: 16,
  "2xl": 18,
};

export const maxRowsPerPage = {
  sm: 10,
  md: 8,
  lg: 6,
  xl: 5,
  "2xl": 5,
};

export const desktopParallaxRanges: { [key: string]: Array<number> } = {
  [SectionIds.About]: [50, 100],
  [SectionIds.Gallery]: [50, 100],
  [SectionIds.MakingOf]: [25, 75],
  [SectionIds.Yarns]: [25, 75],
  [SectionIds.Contact]: [25, 75],
};
