<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="142"
    height="137"
    viewBox="0 0 142 137"
    xml:space="preserve"
  >
    <g transform="matrix(1 0 0 1 71.36 68.51)">
      <path
        transform=" translate(-70.86, -70.87)"
        d="M 124.13 138.88 H 17.6 A 17.62 17.62 0 0 1 0 121.28 V 72.62 A 17.49 17.49 0 0 1 5.15 60.18 L 56.52 8.8 a 20.28 20.28 0 0 1 28.68 0 l 51.37 51.38 a 17.49 17.49 0 0 1 5.15 12.45 v 48.65 A 17.62 17.62 0 0 1 124.13 138.88 Z M 70.87 15.74 a 7.35 7.35 0 0 0 -5.23 2.17 L 14.27 69.29 a 4.75 4.75 0 0 0 -1.38 3.33 v 48.65 A 4.72 4.72 0 0 0 17.6 126 H 124.13 a 4.72 4.72 0 0 0 4.71 -4.72 V 72.62 a 4.75 4.75 0 0 0 -1.38 -3.33 L 76.1 17.91 A 7.35 7.35 0 0 0 70.87 15.74 Z"
        stroke-linecap="round"
        :style="{ fill: color }"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: "#606060",
    },
  },
});
</script>
