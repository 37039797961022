import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71271356"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative pb-12 md:pb-16" }
const _hoisted_2 = {
  key: 0,
  class: "p-0 absolute -top-8 md:-top-10 right-0 justify-end"
}
const _hoisted_3 = { ref: "container" }
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = {
  key: 1,
  class: "p-0 absolute bottom-1 md:bottom-2 right-0 justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BSection = _resolveComponent("BSection")!
  const _component_BPhotosModal = _resolveComponent("BPhotosModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BSection, {
      sectionId: _ctx.SectionIds.Gallery,
      name: _ctx.title,
      img: _ctx.coverPhoto
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.numberOfPages > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_BPagination, {
                  numberOfPages: _ctx.numberOfPages,
                  currentPage: _ctx.pageNumber + 1,
                  onPreviousPage: _ctx.previousPage,
                  onNextPage: _ctx.nextPage
                }, null, 8, ["numberOfPages", "currentPage", "onPreviousPage", "onNextPage"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentPage, (photo) => {
              return (_openBlock(), _createElementBlock("img", {
                key: photo.id,
                src: photo.url,
                ref: (ref) => ref && photo.setElement(ref),
                class: "cursor-pointer",
                onClick: ($event: any) => (_ctx.showModal(photo.id))
              }, null, 8, _hoisted_4))
            }), 128))
          ], 512),
          (_ctx.numberOfPages > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_BPagination, {
                  numberOfPages: _ctx.numberOfPages,
                  currentPage: _ctx.pageNumber + 1,
                  onPreviousPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.previousPage(true))),
                  onNextPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nextPage(true)))
                }, null, 8, ["numberOfPages", "currentPage"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["sectionId", "name", "img"]),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (_ctx.isModalShowing)
          ? (_openBlock(), _createBlock(_component_BPhotosModal, {
              key: 0,
              photoId: _ctx.clickedPhotoId,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isModalShowing = false))
            }, null, 8, ["photoId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}