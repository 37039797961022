/* eslint-disable @typescript-eslint/no-explicit-any */
import useId from "@/hooks/useId";

export class BImage {
  public id: number;
  public url: string;
  public width: number;
  public height: number;
  public element: HTMLElement | undefined;

  constructor(json: any, fixedHeight?: number, fixedWidth?: number) {
    this.id = useId();
    if (fixedHeight) {
      this.height = fixedHeight;
      this.width = (this.height * json.width) / json.height;
    } else if (fixedWidth) {
      this.width = fixedWidth;
      this.height = (this.width * json.height) / json.width;
    } else {
      this.width = json.width;
      this.height = json.height;
    }

    this.height = Math.ceil(this.height);
    this.width = Math.ceil(this.width);

    this.url = json.url + `?w=${Math.round(this.width)}&h=${this.height}`;
  }

  public setElement(element: HTMLElement): void {
    this.element = element;
    this.element.style.width = this.width + "px";
    this.element.style.height = this.height + "px";
  }

  public setWidth(value: number) {
    this.height = (value * this.height) / this.width;
    this.width = value;
    if (this.element) {
      this.element.style.width = this.width + "px";
      this.element.style.height = this.height + "px";
    }
  }

  public setHeight(value: number) {
    this.width = (value * this.width) / this.height;
    this.height = value;
    if (this.element) {
      this.element.style.width = this.width + "px";
      this.element.style.height = this.height + "px";
    }
  }
}
