const scrollToSection = (sectionId: string) => {
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const scrollPosition = document.getElementById(sectionId)!.offsetTop;

    window.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: "smooth",
    });
  }, 250);
};

export default scrollToSection;
