
import { MinusIcon, PlusIcon } from "@/components";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: 10,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    initialValue: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    const increase = () => {
      let newValue;
      if (props.modelValue + props.step > props.max) {
        newValue = props.max;
      } else {
        newValue = props.modelValue + props.step;
      }
      emit("update:modelValue", newValue);
    };

    const decrease = () => {
      let newValue;
      if (props.modelValue - props.step < props.min) {
        newValue = props.min;
      } else {
        newValue = props.modelValue - props.step;
      }
      emit("update:modelValue", newValue);
    };

    return {
      increase,
      decrease,
    };
  },
});
