class Footer {
  public readonly schedule: string;
  public readonly instagram: string;
  public readonly message: string;
  public readonly instagramLink: string;
  constructor(json: any) {
    this.schedule = json.schedule;
    this.instagram = json.instagram;
    this.message = json.message;
    this.instagramLink = json.instagramLink;
  }
}

export default Footer;
