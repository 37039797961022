
import { DownArrow, BPagination } from "@/components";
import { SlideShowItem } from "@/models";
import { defineComponent, computed, ref } from "vue";
import { useCurrentBreakpoint } from "@/hooks";

export default defineComponent({
  components: {
    DownArrow,
    BPagination,
  },
  props: {
    slides: {
      type: Object as () => Array<SlideShowItem>,
      required: true,
    },
    withPagination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentBreakPoint = useCurrentBreakpoint();
    const showMobileLayout = computed(() => {
      return currentBreakPoint.value === "sm";
    });
    const slideNumber = ref(0);
    const numberOfSlides = ref(props.slides.length);
    const currentSlide = computed(() => {
      return props.slides[slideNumber.value];
    });
    const nextSlide = () => {
      if (slideNumber.value === numberOfSlides.value - 1) {
        slideNumber.value = 0;
      } else {
        slideNumber.value++;
      }
    };

    const previousSlide = () => {
      if (slideNumber.value === 0) {
        slideNumber.value = numberOfSlides.value - 1;
      } else {
        slideNumber.value--;
      }
    };
    return {
      numberOfSlides,
      currentSlide,
      slideNumber,
      nextSlide,
      previousSlide,
      showMobileLayout,
    };
  },
});
