import { query } from "@/api/query";
import { MainPage } from "@/models";

export const fetchContent = async (locale: string): Promise<any> => {
  let fetchUrl;
  let headers;

  // In production the content request is sent to the base url which routes the request to Contentful.
  // In development mode, the request is sent directly to Contentful.
  if (process.env.NODE_ENV === "development") {
    fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
    };
  } else {
    fetchUrl = `${process.env.VUE_APP_BASE_URL}/content`;
    headers = {
      "Content-Type": "application/json",
    };
  }

  const fetchOptions = {
    method: "POST",
    headers,
    body: JSON.stringify({
      query: query(locale),
    }),
  };

  try {
    const response = await fetch(fetchUrl, fetchOptions as any).then(
      (response) => response.json()
    );
    return new MainPage(response.data);
  } catch (error) {
    throw new Error("Could not receive the data from Contentful!");
  }
};
