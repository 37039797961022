import { BImage } from "@/models/Image";
import {
  computed,
  Ref,
  ref,
  getCurrentInstance,
  watch,
  onBeforeUnmount,
} from "vue";

const ZOOM_SCALES = [1, 2, 4];

const getContainerSizes = (image: BImage): Array<number> => {
  const screenWidth = window.innerWidth || screen.width;
  const screenHeight = window.innerHeight || screen.height;
  let containerHeight;
  let containerWidth;

  if (screenHeight > screenWidth) {
    containerWidth = screenWidth * 0.8;
    containerHeight = (containerWidth * image.height) / image.width;
  } else {
    containerHeight = screenHeight * 0.8;
    containerWidth = (containerHeight * image.width) / image.height;
  }

  return [containerHeight, containerWidth];
};
export default function useImageZoom(
  container: Ref<HTMLElement>,
  image: Ref<BImage>
): any {
  const zoomValue = ref(ZOOM_SCALES[0]);
  const bgX = ref(50);
  const bgY = ref(50);

  const containerStyle = computed(() => {
    const [height, width] = getContainerSizes(image.value);
    return {
      height: height + "px",
      width: width + "px",
    };
  });

  const containerClasses = computed(() => {
    return [
      zoomValue.value === ZOOM_SCALES[ZOOM_SCALES.length - 1]
        ? "cursor-minus"
        : "cursor-plus",
    ];
  });

  const photoStyle = computed(() => {
    return {
      "transform-origin": `${bgX.value}% ${bgY.value}%`,
      transform: `scale(${zoomValue.value})`,
      transition: "all 0.3s ease-out ",
      "will-change": "transform-origin",
      dragabble: false,
    };
  });

  const onClick = (): void => {
    const currentIndex = ZOOM_SCALES.findIndex((el) => el === zoomValue.value);
    const nextIndex =
      currentIndex < ZOOM_SCALES.length - 1 ? currentIndex + 1 : 0;
    zoomValue.value = ZOOM_SCALES[nextIndex];
  };

  const handleMouseMove = (e: MouseEvent): void => {
    requestAnimationFrame(() => {
      const dimensions = container.value.getBoundingClientRect();
      const [x, y] = [e.clientX - dimensions.left, e.clientY - dimensions.top];
      const [percentX, percentY] = [
        Math.round(100 / (dimensions.width / x)),
        Math.round(100 / (dimensions.height / y)),
      ];

      bgX.value = percentX;
      bgY.value = percentY;
    });
  };

  const reset = () => {
    bgX.value = 50;
    bgY.value = 50;
    zoomValue.value = ZOOM_SCALES[0];
  };

  watch(container, () => {
    container.value.addEventListener("click", onClick);
    container.value.addEventListener("mousemove", handleMouseMove);
  });

  if (getCurrentInstance()) {
    onBeforeUnmount(() => {
      container.value.addEventListener("click", onClick);
      container.value.removeEventListener("mousemove", handleMouseMove);
    });
  }

  return {
    containerStyle,
    containerClasses,
    photoStyle,
    reset,
  };
}
