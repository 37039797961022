<template>
  <BModal class="photos-modal h-screen">
    <template #body>
      <CloseIcon
        class="
          h-6
          md:h-10
          absolute
          top-4
          right-4
          fill-current
          text-white
          opacity-80
          cursor-pointer
          hover:opacity-100
          transition
        "
        @click="() => $emit('close')"
      />

      <div></div>

      <div
        ref="photoContainer"
        :style="containerStyle"
        draggable="false"
        class="group overflow-hidden relative unselectable"
        :class="containerClasses"
      >
        <img
          :src="currentPhoto.url"
          class="absolute unselectable"
          :style="photoStyle"
          draggable="false"
          @ondragstart.prevent="(e) => e.preventDefault()"
        />
      </div>

      <DownArrow
        class="arrow left cursor-pointer"
        @click.stop="previousPhoto"
      />
      <DownArrow class="arrow right cursor-pointer" @click.stop="nextPhoto" />
    </template>
  </BModal>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { BModal, DownArrow, CloseIcon } from "@/components";
import { useImageZoom } from "@/hooks";

export default defineComponent({
  components: { BModal, DownArrow, CloseIcon },
  props: {
    photoId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const photoContainer = ref(null);
    const store = useStore();
    const { photos } = store.state.content.gallerySection;
    const currentPhotoIndex = ref(
      photos.findIndex((photo) => photo.id === props.photoId)
    );

    const currentPhoto = computed(() => {
      return photos[currentPhotoIndex.value];
    });

    const {
      containerStyle,
      containerClasses,
      photoStyle,
      reset: resetImagePosition,
    } = useImageZoom(photoContainer, currentPhoto);

    const nextPhoto = () => {
      if (currentPhotoIndex.value === photos.length - 1) {
        currentPhotoIndex.value = 0;
      } else {
        ++currentPhotoIndex.value;
      }
      resetImagePosition();
    };

    const previousPhoto = () => {
      if (currentPhotoIndex.value === 0) {
        currentPhotoIndex.value = photos.length - 1;
      } else {
        --currentPhotoIndex.value;
      }
      resetImagePosition();
    };
    return {
      photos,
      currentPhoto,
      containerStyle,
      containerClasses,
      photoStyle,
      photoContainer,
      nextPhoto,
      previousPhoto,
    };
  },
});
</script>

<style scoped lang="postcss">
.photos-modal {
  width: 100%;

  img {
    max-height: 80%;
    max-width: 80%;
  }
}

.unselectable {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.overlay {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 255, 235, 0) 0%,
    rgba(0, 0, 0, 0.33) 41%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.arrow {
  @apply fill-current text-white  opacity-80 h-12 p-2 absolute bottom-4;
  transition: right 200ms, left 200ms, opacity 200ms;
}

.arrow.left {
  @apply left-0;
  transform: translate(0, -40%) rotate(90deg);
}

.arrow.right {
  @apply right-0;
  transform: translate(0, -40%) rotate(-90deg);
}

.arrow.left:hover {
  @apply -left-1 opacity-100;
}

.arrow.right:hover {
  @apply -right-1 opacity-100;
}

.arrow.disabled {
  @apply opacity-50 cursor-default pointer-events-none;
}

@media (min-width: theme("screens.md")) {
  .arrow {
    @apply h-20 top-1/2;
  }
}
</style>
