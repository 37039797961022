<template>
  <BSection :sectionId="SectionIds.Yarns" :name="title" :img="coverPhoto">
    <div v-if="showMobileLayout">
      <div class="mobile-img-grid">
        <div class="img-container" v-for="index in [0, 1]" :key="index">
          <img
            :src="mobileSetOne[index].url"
            :class="{
              'opacity-100': mobileSetNumber === 0,
              'opacity-0': mobileSetNumber !== 0,
            }"
          />
          <img
            :src="mobileSetTwo[index].url"
            :class="{
              'opacity-100': mobileSetNumber === 1,
              'opacity-0': mobileSetNumber !== 1,
            }"
          />
          <img
            :src="mobileSetThree[index].url"
            :class="{
              'opacity-100': mobileSetNumber === 2,
              'opacity-0': mobileSetNumber !== 2,
            }"
          />
          <img
            :src="mobileSetFour[index].url"
            :class="{
              'opacity-100': mobileSetNumber === 3,
              'opacity-0': mobileSetNumber !== 3,
            }"
          />
        </div>
      </div>
      <div>
        <div class="text mt-4 text-xs">{{ text1 }}</div>
        <div class="text mt-4 text-xs">{{ text2 }}</div>
        <div class="text mt-4 text-xs">{{ text3 }}</div>
      </div>
    </div>
    <div v-else class="desktop-grid relative">
      <div class="text">{{ text1 }}</div>
      <div class="text">{{ text2 }}</div>
      <div class="text">{{ text3 }}</div>
      <div class="img-container" v-for="index in [0, 1, 2]" :key="index">
        <img
          :src="desktopSetOne[index].url"
          :class="{
            'opacity-100': desktopSetNumber === 0,
            'opacity-0': desktopSetNumber !== 0,
          }"
        />
        <img
          :src="desktopSetTwo[index].url"
          :class="{
            'opacity-100': desktopSetNumber === 1,
            'opacity-0': desktopSetNumber !== 1,
          }"
        />
        <img
          :src="desktopSetThree[index].url"
          :class="{
            'opacity-100': desktopSetNumber === 2,
            'opacity-0': desktopSetNumber !== 2,
          }"
        />
      </div>
    </div>
  </BSection>
</template>

<script>
// TODO:
// - REFACTOR THIS making it more flexible

import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import { BSection } from "@/components";
import { SectionIds } from "@/enums";
import { useCurrentBreakpoint } from "@/hooks";

export default defineComponent({
  components: { BSection },
  setup() {
    const store = useStore();
    const currentBreakPoint = useCurrentBreakpoint();
    const { title, coverPhoto, photos, text1, text2, text3 } =
      store.state.content.yarnsSection;

    // Desktop related properties
    const desktopSetNumber = ref(0);
    const desktopSetOne = computed(() => {
      return photos.slice(0, 3);
    });
    const desktopSetTwo = computed(() => {
      return photos.slice(3, 6);
    });
    const desktopSetThree = computed(() => {
      return photos.slice(5, 8);
    });

    setInterval(() => {
      desktopSetNumber.value =
        desktopSetNumber.value < 2 ? desktopSetNumber.value + 1 : 0;
    }, 5000);

    // Mobile related properties
    const showMobileLayout = computed(() => {
      return currentBreakPoint.value === "sm";
    });

    const mobileSetNumber = ref(0);
    const mobileSetOne = computed(() => {
      return photos.slice(0, 2);
    });
    const mobileSetTwo = computed(() => {
      return photos.slice(2, 4);
    });
    const mobileSetThree = computed(() => {
      return photos.slice(4, 6);
    });

    const mobileSetFour = computed(() => {
      return photos.slice(6, 8);
    });

    setInterval(() => {
      mobileSetNumber.value =
        mobileSetNumber.value < 3 ? mobileSetNumber.value + 1 : 0;
    }, 5000);

    return {
      SectionIds,
      title,
      coverPhoto,
      desktopSetOne,
      desktopSetTwo,
      desktopSetThree,
      text1,
      text2,
      text3,
      desktopSetNumber,
      showMobileLayout,
      mobileSetOne,
      mobileSetTwo,
      mobileSetThree,
      mobileSetFour,
      mobileSetNumber,
    };
  },
});
</script>

<style scoped lang="postcss">
.text {
  @apply text-gray-elke;
  text-align: justify;
}

.desktop-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
}

.img-container {
  position: relative;
  width: 100%;
  padding-top: 80%;
}

img {
  @apply transition duration-1000;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.mobile-img-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  height: 170px;
}

.mobile-img-grid .img-container {
  padding-top: 0px;
}
</style>
