
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { About, Gallery, MakingOf, Contact, Yarns } from "@/sections";
import { BFooter } from "@/components";
import { useStore } from "vuex";
import LineLogo from "@/components/LineLogo.vue";

export default defineComponent({
  name: "Main",
  components: { About, Gallery, MakingOf, Contact, Yarns, BFooter, LineLogo },
  setup() {
    const store = useStore();
    const logo = ref(null as unknown as HTMLElement);
    const { landingPhoto } = store.state.content;
    const style = ref({
      "background-image": `url("${landingPhoto.url}")`,
    });

    const repositionLineLogo = () => {
      requestAnimationFrame(() => {
        const screenHeight = window.innerHeight || screen.height;
        const topOfScreen =
          window.scrollY || document.documentElement.scrollTop;
        const bottomOfScreen = topOfScreen + screenHeight;
        const elBounding = logo.value.getBoundingClientRect();
        const screenRangeLow = topOfScreen - elBounding.height;
        const screenRangeHigh = bottomOfScreen + elBounding.height;
        const screenRange = screenRangeHigh - screenRangeLow;
        const elementTop = elBounding.top + topOfScreen;
        const elementBottom = elementTop + elBounding.height;
        const elementPosition = elementTop + elBounding.height / 2;

        const bottomPositionBottom = 5;
        const bottomPositionRange = 100;

        const isInView =
          elementBottom > topOfScreen && elementTop < bottomOfScreen;

        if (isInView) {
          const newPosition =
            ((elementPosition - screenRangeLow) * bottomPositionRange) /
              screenRange +
            bottomPositionBottom;

          logo.value.style.bottom =
            bottomPositionRange / 2 +
            (bottomPositionRange / 2 - newPosition) +
            "px";
        }
      });
    };

    onMounted(() => {
      window.addEventListener("scroll", repositionLineLogo);
      repositionLineLogo();
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", repositionLineLogo);
    });

    return {
      style,
      logo,
    };
  },
});
